import { graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer";
import Privacy from "../components/Privacy";

export default function PrivacyPage({ data }) {
  const footerData = data.footer.frontmatter;

  return (
    <div>
      <Helmet defer={false}>
        <title>Privacy Policy</title>
      </Helmet>
      <Privacy />
      <Footer footerData={footerData} />
    </div>
  );
}

export const query = graphql`
  query PrivacyQuery {
    footer: markdownRemark(fileAbsolutePath: { regex: "/footer.md/" }) {
      frontmatter {
        footerLine1
        mailTo
        telephone
      }
    }
  }
`;
