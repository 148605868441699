import { Link } from "gatsby";
import React from "react";

import * as styles from "./privacy.module.scss";

const Privacy = () => {
  return (
    <div className={styles.container}>
      <h1>PRIVACY POLICY</h1>

      <h2>INTRODUCTION</h2>
      <p>
        Stack Technologies Ltd. doing business as Petro Ninja ("
        <b>Petro Ninja</b>" or "<b>We</b>
        ") respects your privacy and is committed to protecting it through our
        adherence to this Privacy Policy. Terms used in this privacy policy
        shall have the same meaning as in our{" "}
        <Link to="/terms">Terms of Use</Link> unless the context indicates
        otherwise.
      </p>
      <p>
        This Privacy Policy describes the types of information we may collect
        from you or that you may provide when you visit the website
        www.petroninja.com (our "<b>Site</b>") or use our mobile app ("
        <b>App</b>") and information collected through automatic collection
        methods like cookies, beacons and other mechanisms. This Privacy Policy
        also describes our practices for using, maintaining, protecting, and
        disclosing that information. Collectively the Site and App are referred
        to in this Privacy Policy as the "<b>Services</b>".
      </p>
      <p>
        Except as set forth in this Privacy Policy, your Personal Information
        will not be used by us for any other purpose without your consent. We do
        not sell customer lists. You may withdraw your consent to our processing
        of your Personal Information at any time. However, withdrawing consent
        may result in your inability to continue using the Services.
      </p>

      <h2>LIMITATIONS</h2>
      <p>This Privacy Policy does not apply to:</p>
      <ul>
        <li>
          information collected by us through a separate website, app or service
          (such information is governed by the applicable privacy policy for
          that site and service);
        </li>
        <li>
          information collected by us which is non-personally identifiable
          information, such as demographic statistics of our users, number of
          visitors, what pages users access or visit, and average time spent on
          the Website, business contact information or any personal information
          which has been anonymized; or
        </li>
        <li>
          information collected by any third party, including through any
          application or content that may link to or be accessible from or on
          the Site or App.
        </li>
      </ul>

      <h2>MINORS</h2>
      <p>
        The Services are not intended for persons under 18 years of age and
        members of the Services must be 18 or older. We do not knowingly collect
        personal information from persons under 18. If you are under 18, do not
        use or provide any information on this Site or on or through any of its
        features/register on the Site or App, make any purchases through the
        Services, use any of the interactive or public comment features of the
        Services or provide any information about yourself to us, including your
        name, address, telephone number, email address or any screen name or
        user name you may use. If we learn we have collected or received
        personal information from a child under 18 without verification of
        parental consent, we will delete that information.
      </p>

      <h2>INFORMATION WE COLLECT ABOUT YOU AND HOW WE COLLECT IT</h2>
      <p>
        We collect several types of information from and about users of our
        Services, including information:
      </p>
      <ul>
        <li>
          by which you may be personally identified, such as name, postal
          address, email address, telephone number and any other information by
          which you may be identified or which is otherwise associated with
          information by which you be identified ("
          <b>personal information</b>"); and/or
        </li>
        <li>
          that is about you but on its own does not identify you or is not
          otherwise personal information.
        </li>
      </ul>
      <p>We collect this information:</p>
      <ul>
        <li>Directly from you when you provide it to us; or</li>
        <li>Automatically as you use the App or the Site.</li>
      </ul>

      <h2>INFORMATION YOU PROVIDE TO US</h2>

      <br />
      <p>This Privacy Policy was last updated on September 26, 2023.</p>
    </div>
  );
};

export default Privacy;
